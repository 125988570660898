import { IsArray, IsBoolean, IsNotEmpty, IsString, Validate, ValidateNested } from 'class-validator';

import { Dto } from '@domain/models/Dto';
import { ExperimentPeriodicity, ExpPeriodicityTitle } from '@domain/enums/ExperimentPeriodicity';
import { IsValidReportingArpu } from '@domain/models/validationConstraints/IsValidReportingArpu';

class ReportingArpu {
  @IsString()
  value: string;
}

class AppVersions {
  @IsString()
  value: string;
}

export class ExpDefinitionParams extends Dto {
  @IsString()
  @IsNotEmpty()
  public arpu: string;

  @IsArray()
  @Validate(IsValidReportingArpu, {
    message: 'Cannot be greater than target arpu'
  })
  @ValidateNested({ each: true })
  public reportingArpu: ReportingArpu[];

  @IsArray()
  @ValidateNested({ each: true })
  public appVersions: AppVersions[];

  @IsString()
  @IsNotEmpty()
  public checkPeriod: ExperimentPeriodicity;

  @IsBoolean()
  @IsNotEmpty()
  public automaticMode: boolean;

  static ofInitial() {
    const form = new ExpDefinitionParams();

    form.arpu = '';
    form.reportingArpu = [{ value: '' }];
    form.appVersions = [{ value: '' }];
    form.checkPeriod = ExperimentPeriodicity.TWICE_PER_DAY;
    form.automaticMode = false;

    return form;
  }

  getSummary(): { title: string; value: string }[] {
    return [
      { title: 'Target ARPU', value: String(this.arpu) },
      { title: 'Reporting ARPU', value: this.reportingArpu.map(({ value }) => value).join(' / ') },
      { title: 'App version', value: this.appVersions.map(({ value }) => value).join(' / ') },
      { title: 'Check Period', value: ExpPeriodicityTitle[this.checkPeriod] },
      { title: 'Automatic mode', value: String(this.automaticMode) }
    ];
  }
}
