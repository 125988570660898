import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';
import cn from 'classnames';

import { useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExperimentState } from '@domain/enums/ExperimentState';

import styles from '../../../../ExperimentDetails.module.scss';

type Props = {
  openEditor: () => void;
  closeEditor: () => void;
  editorEnabled: boolean;
  submitLabel?: string;
  handleSubmit: () => void;
  disableSubmit?: boolean;
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
};

export function FormControls({
  openEditor,
  closeEditor,
  editorEnabled,
  submitLabel,
  handleSubmit,
  disableSubmit,
  isLoading,
  className,
  disabled
}: Props) {
  const { state } = useSelector(experimentDetailsSelectors.getExperiment);

  const isDisabled = state !== ExperimentState.RUNNING || disabled;

  if (editorEnabled) {
    return (
      <div className={cn(styles.formControls, className)}>
        <Button variant={ButtonVariant.SECONDARY} onClick={closeEditor} disabled={isLoading}>
          Cancel
        </Button>
        <Button disabled={disableSubmit} onClick={handleSubmit} isLoading={isLoading}>
          {submitLabel || 'Save'}
        </Button>
      </div>
    );
  }
  return (
    <div className={cn(styles.formControls, className)}>
      <Button onClick={openEditor} variant={ButtonVariant.SECONDARY} isLoading={isLoading} disabled={isDisabled}>
        Edit
      </Button>
    </div>
  );
}
